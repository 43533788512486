module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Mate Portfolio","short_name":"Mate","start_url":"/","background_color":"#FFFFFF","theme_color":"#7c37ad","display":"minimal-ui","icon":"icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"bd811806cc1b8afc275a716b064f3106"},
    },{
      plugin: require('../node_modules/gatsby-theme-mate/gatsby-browser.js'),
      options: {"plugins":[],"accessToken":"3M5_vKYrCkVhPI9iQzE9GsTKFkAU3sk1A_7zH93AafY","spaceId":"q90i3n79vo6i"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-100117464-3","head":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0},
    }]
